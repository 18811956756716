@use "sass:map";

@import 'global-breakpoints';

@media #{map-get($breakpoints, "small")} {
	body {
		font-size: 1em;
		width: 100%;
	}

	.main-content {
        margin: 0.5em;
        padding: 1em 8em 8em 8em;
	}

	.main-grid {
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
	}

	.container {
		display: block;
		padding: 3em 1.5em;
		min-width: 200px;
	}

	.text-content {
		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}
  }