@import 'global-desktop';
@import 'global-large-phone';
@import 'global-medium-phone';
@import 'global-small-phone';
@import 'global-syntaxhighlighting';

@import 'component-nav';
@import 'component-social-card';
@import 'component-lightbox';
@import 'component-blog-post-list';
@import 'component-header';
@import 'component-post-metadata';
@import 'component-rss-button';
@import 'component-tldr';

html body {
	width: 100vw;
    height: 100%;
    background-color: black;
    background-image: url('/assets/img/repeated-square-dark.png');
    overflow-x: hidden;
    font-family: 'Courier New', Courier, monospace;
	padding: 0;
	margin: 0;
}

h3 {
	text-align: center;
	color: rgb(80, 80, 80);
	font-weight: bold;
	margin: 0.5rem 0;
}

li {
	padding: 0.25em 0px;
}

time {
	font-weight: bold;
}

blockquote {
	background-color: #f8f8ff;
	color: #2b2b2b;
	padding: 0px 4px;
	font-size: 0.95rem;
	border: 1px solid #ebebff;
}

pre {
	white-space: pre-wrap;
	word-break: break-word;
}

img {
  max-width: 100%;
}

p {
	word-break: break-word;
}

p > a > img {
	display: block;
	margin: 2em auto;
}

.post-title {
	text-align: center;
	margin: 0.5em 0.25em;
}

.main-content {
	width: auto;
	height: 100%;
	background-color: white;
	border-radius: 5px;
	box-shadow: inset 0 0 20px #a8a8a8;
}

.highlighter-rouge {
	background-color: #f8f8f8;
	border: 1px solid #ececec;
	border-radius: 4px;
	padding: 1px 2px;
}

.primary-content-break {
    display: block;
    width: 100%;
    height: 0.2em;
    background-color: rgb(60, 60, 60);
    border-bottom: solid 0.1rem #023f57;
    border-radius: 16px;
}

.secondary-content-break {
    display: block;
    width: 100%;
    height: 0.12em;
    background-color: rgb(165, 165, 165);
	border-bottom: solid 0.05em #919191;
}

.highlight {
	padding: 0px 4px;
}

.main-grid {
	display: grid;
	max-width: 75vw;
	margin-left: auto;
	margin-right: auto;
	border-left: 1px solid #a9a9a926;
	border-right: 1px solid #a9a9a926;
}

.text-content {
	padding: 1em;
}

.margin-top-sm {
	margin-top: 2em;
}

.margin-bottom-sm {
	margin-bottom: 2em;
}

.rss-feed-container {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	padding: 0 1em;
}