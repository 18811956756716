.lightbox {
	display: none;
	position: fixed;
	z-index: 2;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.8);

    img {
        max-width: 92%;
        max-height: 90%;
        margin-top: 2%;
    }
}

.lightbox:target {
	outline: none;
	display: block;
}