@use "sass:map";

@import 'global-breakpoints';

@mixin social-card($icon-max-height) {
    .social-card {
        padding: 1em;
        grid-column: 1 / 2;
        grid-row: 1 / 1;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    
        .profile-image {
            display: inline-block;
            width: 150px;
            height: auto;
            border-radius: 50%;
          
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        
            border: 2px solid darkgray;

            min-width: 100px;
        }
    
        .social-links {
            width: 100%;
            height: 1.1em;
            padding: 0.5em;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
    
            .social-icon {
                display: inline-block;
            }
        }
        
        .social-links > * > * {
            display: inline-block;
            vertical-align: middle;

            max-height: $icon-max-height;
        }

        a {
            text-decoration: none;
        }
    }
}

@media #{map-get($breakpoints, "medium")} {
    @include social-card(0.85em);
}

@media #{map-get($breakpoints, "large")} {
    @include social-card(0.85em);
}

@include social-card(1.5em);