@use "sass:map";

$breakpoint-small: 480;
$breakpoint-medium: 768;
$breakpoint-large: 1200;

$breakpoints: (
  "small": "all and (max-width: #{$breakpoint-small + 0px})",
  "medium": "all and (min-width: #{$breakpoint-small + 1px}) and (max-width: #{$breakpoint-medium + 0px})",
  "large": "all and (min-width: #{$breakpoint-medium + 1px}) and (max-width: #{$breakpoint-large + 0px})",
  "desktop": "all and (min-width: #{$breakpoint-large + 1px})",
  "xl-desktop": "all and (min-width: 100em)"
);