@media all and (min-width: 1201px) {
	body {
		font-size: 1.1em;
	}

	.main-content {
		margin: 2rem 4em;
		padding: 2.5% 5% 5% 5%;
	}

	.main-grid {
		grid-template-columns: auto 7fr;
		grid-template-rows: auto;
	}

	.text-content {
		grid-column: 2 / 3;
		grid-row: 1 / 1;
	}

	.image-content {
		margin: 0;
	}
	
	.container {
		display: block;
		margin: 3em auto;
		max-width: 75vw;
	}

	.profile-image {
		min-width: 150px;
	}

	.social-links {
		padding-top: 1em;
	}

	.social-links > * {
		max-height: 1.5em;
	}
}