@use "sass:map";

@import 'global-breakpoints';

@media #{map-get($breakpoints, "medium")} {
	body {
		font-size: 1.1em;
	}

	.main-content {
		margin: 2rem;
		padding: 2.5% 5% 5% 5%;
	}

	.main-grid {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
	}

	.container {
		display: block;
		padding: 3em;
	}

	.text-content {
		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}
  }