.reading-estimate {
	display: flex;
	text-align: right;
	color: gray;
    justify-content: space-between;

    time {
        margin-right: 2em;
        display: inline-flex;
        align-items: center;
    }

    div {
        display: inline-block;
        text-align: center;

        div {
            display: block;
            border-bottom: 1px solid rgba(211, 211, 211, 0.5);
        }

        div:nth-child(1) {
            margin-left: 2em;
            margin-bottom: 0.25em
        }
    
        div:nth-child(3) {
            margin-right: 2em;
            margin-top: 0.25em
        }
    }
}