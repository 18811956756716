@use "sass:map";

@import 'global-breakpoints';

@media #{map-get($breakpoints, "small")} {
    .tldr {
        max-width: 100vw;

        details summary {
            flex-direction: column;
            text-align: center;

            span {
                margin-top: 0.5em;
            }
        }

        details summary::after {
            right: 0.4em;
        }
    }
}

@media #{map-get($breakpoints, "medium")} {
    .tldr {
        max-width: 75vw;

        details summary {
            flex-direction: column;
            text-align: center;

            span {
                margin-top: 0.5em;
            }
        }

        details summary::after {
            right: 0.4em;
        }
    }
}

@media #{map-get($breakpoints, "large")} {
    .tldr {
        max-width: 75vw;

        details summary {
            flex-direction: row;
        }

        details summary::after {
            right: 0.25em;
        }
    }
}

@media #{map-get($breakpoints, "desktop")} {
    .tldr {
        max-width: 60vw;

        details summary {
            flex-direction: row;
        }

        details summary::after {
            right: 0.25em;
        }
    }
}

.tldr {

    margin: 1em auto 2em auto;

    &::before {
        content: '';
        position: relative;
        display: inline-block;
        top: 14px;
        left: 0;
        border-right: 8px solid transparent;
        border-top: 8px solid rgb(230, 230, 230);
        z-index: 1;
    }

    details {
        background-color: #fbfbfb;
        padding: 0 2.25em 0 1em;
        box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
        border-width: 1px;
        border-style: solid;
        border-image: 
            linear-gradient(
                to top, 
                rgb(212, 212, 212),
                rgba(212, 212, 212, 0.25)
            ) 1 1 1 1;
        border-bottom: 1px solid gray;
    }

    details[open] summary ~ * {
        animation: open 0.25s ease-in-out;
    }

    details summary::-webkit-details-marker {
        display: none;
    }
      
    details summary {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem 2em 0.5rem 0;
        position: relative;
        cursor: pointer;
        outline: 0;

        span {
            color: rgb(165, 165, 165);
        }

        h3 {
            margin: 0;
        }
    }
      
    details summary:after {
        content: "+";
        color: black;
        position: absolute;
        font-size: 1.75rem;
        line-height: 0;
        margin-top: 0.75rem;
        font-weight: bold;
        transform-origin: center;
        transition: 200ms linear;
    }
    details[open] summary:after {
        transform: rotate(45deg);
        font-size: 2rem;
        color: red;
    }

    @keyframes open {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
} 