@use "sass:map";

@import 'global-breakpoints';

@media #{map-get($breakpoints, "small")} {
    nav {
        justify-content: space-around;
    }
    nav > a {
        margin: 0 0.6em !important;
    }
}

nav {
    padding: 0.25em;
    margin: 0.1em 0;
    display: flex;
    align-items: center;
}

nav > a {
    text-decoration: none;
    text-align: center;
    margin: 0 1em;
}