@mixin rss-button($height) {
    $background-color: #f7a833;
    $background-color-light: #ffc067;
    $background-color-darkened: #f19823;
    $background-color-logo: white;
    $border-color: rgb(170, 170, 170);

    .rss-button {
        display: block;
        width: $height;
        height: $height;
    
        a {
            background-color: $background-color-darkened;
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid $border-color;
            transition: all 250ms ease;
        }
        a:hover {
            box-shadow: 0 ($height/7) ($height/6) 0 rgba(0, 0, 0, 0.30);
        }
        a:hover .bottom, a:hover .curve:nth-child(odd) {
            background: $background-color-darkened;
        }
        a:hover .top {
            background-color: $background-color-darkened;
        }
        a:hover .fold {
            border-right: ($height/6) solid transparent;
            border-bottom: ($height/6) solid $background-color-light;
        }

        .top-border {
            position: absolute;
            background-color: $background-color-logo;
            right: -1px;
            top: -1px;
            border-right: ($height/6) solid transparent;
            border-top: ($height/6) solid $background-color-logo;
        }

        .top {
            width: 85%;
            height: 15%;
            background-color: $background-color;
            position: absolute;
            z-index: 1;
        }
          
        .bottom {
            width: 100%;
            height: 85%;
            top: 15%;
            position: absolute;
            background: $background-color;
            z-index: 1;
        }
          
        .fold {
            width: 0;
            height: 0;
            right: 0;
            top: 0;
            position: absolute;
            border-right: ($height/6) solid transparent;
            border-bottom: ($height/6) solid $background-color-light;
            z-index: 2;
        }
          
        .circle {
            width: 20%;
            height: 20%;
            position: absolute;
            bottom: 15%;
            left: 15%;
            background: $background-color-logo;
            border-radius: 100%;
            z-index: 7;
        }
          
        .center {
            padding: 42%;
            overflow: hidden;
            position: relative;
            top: 0;
            left: 17%;
        }
        
        .curve {
            border-radius: 50%;
            position: absolute;
        }
         
        .curve:nth-child(even) {
            background: $background-color-logo;
        }

        .curve:nth-child(odd) {
            background: $background-color;
        }
          
        .curve:nth-child(1) {
            width: 70%;
            height: 80%;
            bottom: -41%;
            left: -36%;
            z-index: 4;
        }

        .curve:nth-child(2) {
            width: 93%;
            height: 109%;
            bottom: -55%;
            left: -46%;
            z-index: 3;
        }

        .curve:nth-child(3) {
            width: 115%;
            height: 138%;
            bottom: -70%;
            left: -58%;
            z-index: 2;
        }
        
        .curve:nth-child(4) {
            width: 138%;
            height: 163%;
            bottom: -80%;
            left: -68%;
            z-index: 1;
        }   
    }
}

@include rss-button(1.75em);