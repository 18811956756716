header {
    h1 {
        text-align: center;
        margin: 0.5rem 0;
        letter-spacing: 0.25rem;
        line-height: 1em;
    }
    
    h2 {
        text-align: center;
        color: rgb(110, 110, 110);
        font-weight: normal;
        margin: 0.5rem 0;
    }
}